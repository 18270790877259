<template>
  <div class="form-group">
    <label>{{label}}</label>
    <input type="file" class="form-control" @change="$emit('update:value', multiple ? $event.target.files : $event.target.files[0])" :multiple="multiple" />
    <small class="text-danger">
    </small>
  </div>
</template>

<script>
export default {
  props: ["label", 'multiple'],
};
</script>